import React, { useState } from "react"
import styled from "styled-components"
import HeroHeader from "../components/heroHeader"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import RichContentTextBlock from "../components/richContentTextBlock"
import { MapContainer, TileLayer, Marker, MapConsumer } from "react-leaflet"
import L from "leaflet"
import { Box, Heading, Flex, Image } from "@theme-ui/components"
import activePin from "../images/map-pin-active.svg"
import { getWherePath } from "../components/pathUtils"
import { HelmetDatoCms } from "gatsby-source-datocms"

const zoomInLevel = 14

function LocationMarker({ city, setCity, currentCity }) {
  const icon = new L.Icon({
    iconUrl: require("../images/map-pin.svg"),
    iconRetinaUrl: require("../images/map-pin.svg"),
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(36, 50),
  })

  const activeIcon = new L.Icon({
    iconUrl: require("../images/map-pin-active.svg"),
    iconRetinaUrl: require("../images/map-pin-active.svg"),
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 70),
  })

  return (
    <Marker
      position={[city.coordinates.latitude, city.coordinates.longitude]}
      icon={currentCity.originalId === city.originalId ? activeIcon : icon}
      key={city.originalId}
      eventHandlers={{
        click: e => {
          setCity(city)
          e.target["_map"].flyTo(e.latlng, zoomInLevel, {})
        },
      }}
    />
  )
}

const WherePage = ({ data: { page, site }, pageContext, location }) => {
  // console.log(page)
  const locale = pageContext.locale
  const i18nPaths = site.locales
    .filter(locale => locale !== "fr")
    .map(locale => {
      return {
        locale: locale,
        value: getWherePath(locale),
      }
    })

  const [currentCity, setCity] = useState(page.locations[0])
  const [map, setMap] = useState()
  const locationBounds = page.locations.map(city => [
    city.coordinates.latitude,
    city.coordinates.longitude,
  ])
  const bounds = locationBounds

  return (
    <Layout
      lang={locale}
      title={page.title}
      location={location}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags}>
        <html lang={locale} />
      </HelmetDatoCms>
      <HeroHeader title={page.title} heroImage={page.heroImage} />
      <Box sx={{ position: "relative" }}>
        <Flex
          sx={{
            flexDirection: ["column", "column", "row"],
            height: ["auto", "auto", "80vh"],
          }}
        >
          <Box
            sx={{
              overflow: "scroll",
              width: ["100%", "100%", "50%"],
              paddingLeft: [3, 4, "5%", "10%"],
              paddingRight: [3, 4, "5%", "64px"],
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            {page.locations.map(city => (
              <Box
                sx={{
                  borderBottom: "1px solid",
                  position: "relative",
                  borderColor: "#ccc",
                  backgroundColor:
                    city.originalId === currentCity.originalId
                      ? "#fafafa"
                      : null,
                  p: [3, 3, 4],
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#fafafa",
                  },
                }}
                onClick={() => {
                  map.flyTo(
                    [city.coordinates.latitude, city.coordinates.longitude],
                    zoomInLevel
                  )
                  setCity(city)
                }}
              >
                <Heading
                  sx={{
                    fontSize: 4,
                    color:
                      city.originalId === currentCity.originalId
                        ? "primary"
                        : "dark",
                    mb: 3,
                  }}
                >
                  {city.name}
                </Heading>
                <RichContentTextBlock html={city.text} />
                {city.originalId === currentCity.originalId && (
                  <Image
                    src={activePin}
                    sx={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      width: "30px",
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              width: ["100%", "100%", "50%"],
              height: ["500px", "500px", "100%"],
            }}
          >
            {typeof window !== "undefined" && (
              <Map scrollWheelZoom={false} bounds={bounds}>
                <TileLayer url="https://api.mapbox.com/styles/v1/multiconsult/clypfqqj5006t01pihn6ha24e/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibXVsdGljb25zdWx0IiwiYSI6ImNseXBmZWtkOTB0YXQya3F5M3c1cnhrOTQifQ.HHSRcmfAQaP472CEhniP7Q" />
                {page.locations.map(city => (
                  <LocationMarker
                    city={city}
                    key={city.originalId}
                    setCity={setCity}
                    currentCity={currentCity}
                  />
                ))}
                <MapConsumer>
                  {map => {
                    setMap(map)
                    return null
                  }}
                </MapConsumer>
              </Map>
            )}
          </Box>
        </Flex>
      </Box>
    </Layout>
  )
}

const Map = styled(MapContainer)`
  width: 100%;
  height: 100%;
  @media screen and (max-width: 425px) {
    height: 40vh;
  }
`

export default WherePage

export const query = graphql`
  query WherePageQuery($slug: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsWherePage(slug: { eq: $slug }, locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      title
      text
      id
      locale
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      locations {
        name
        text
        originalId
        coordinates {
          latitude
          longitude
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
